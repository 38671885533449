jQuery(document).ready(function ($) {
	"use strict";

	// $.ajax({
	// 	url: wpApiSettings.ajaxUrl,
	// 	type: 'post',
	// 	data: {
	// 		action: 'it_custom_action',
	// 	},
	// 	success: function (data) {
	// 		console.log(data)
	// 	}
	// });
});

