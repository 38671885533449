/**
 * Throttle helper function
 * @param func
 * @param delay
 * @returns {(function(...[*]): void)|*}
 */

// usage example:
// let f = throttle(custom_function);
// window.addEventListener('resize', f);

export const throttle = (func, delay = 250) => {
  let isThrottled = false;
  let savedArgs = null;
  let savedThis = null;

  return function wrap(...args) {
    if (isThrottled) {
      savedArgs = args,
      savedThis = this;
      return;
    }

    func.apply(this, args);
    isThrottled = true;

    setTimeout(() => {
      isThrottled = false;

      if (savedThis) {
        wrap.apply(savedThis, savedArgs);
        savedThis = null;
        savedArgs = null;
      }

    }, delay);
  }
};
